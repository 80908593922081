import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import ServiceDetails from '../../containers/Services-v2/ServiceDetails';

const propTypes = {
  data: PropTypes.shape({
    serviceWeProvide: PropTypes.shape({
      title: PropTypes.string,
      subHeader: PropTypes.shape({
        subHeader: PropTypes.string,
      }),
      detailsTitle: PropTypes.string,
      detailsDescription: PropTypes.shape({}),
      detailsDescription2: PropTypes.shape({}),
      managementSolutions: PropTypes.shape({}),
      technologySolutions: PropTypes.shape({}),
    }),
    contentfulCompanyQuote: PropTypes.shape({
      title: PropTypes.string,
      quote: PropTypes.string,
      icons: PropTypes.arrayOf({
        fluid: PropTypes.shape({}),
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

const ServiceDetailsPage = ({ data }) => (
  <ServiceDetails
    title={data.serviceWeProvide.title}
    subheader={data.serviceWeProvide.subHeader.subHeader}
    detailsTitle={data.serviceWeProvide.detailsTitle}
    detailsDescription={data.serviceWeProvide.detailsDescription}
    detailsDescription2={data.serviceWeProvide.detailsDescription2}
    managementSolutions={data.serviceWeProvide.managementSolutions}
    technologies={data.serviceWeProvide.technologySolutions}
    companyQuote={data.contentfulCompanyQuote}
  />
);

ServiceDetailsPage.propTypes = propTypes;
export default ServiceDetailsPage;

export const query = graphql`
  query($slug: String!) {
    serviceWeProvide: contentfulServicesWeProvide(slug: { eq: $slug }) {
      slug
      title
      subHeader {
        subHeader
      }
      detailsTitle
      detailsDescription {
        json
      }
      detailsDescription2 {
        json
      }
      technologySolutions {
        title
        description {
          json
        }
        icon {
          fluid(quality: 100, maxWidth: 1000) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      managementSolutions {
        slides {
          data {
            description
            title
          }
        }
        title
        description {
          json
        }
        icon {
          fluid(quality: 100, maxWidth: 1000) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
    contentfulCompanyQuote(label: { eq: "servicesAws" }) {
      title
      quote
      icons {
        fluid(quality: 100, maxWidth: 1000) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`;
