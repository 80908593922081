import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../../../components/Layout';
import PageHeader from '../../../components/PageHeader';
import PageDetails from '../../../components/PageDetails';
import CompanyQuote from '../../../components/CompanyQuote';
import ManagementSolutions from './ManagementSolutions';
import TechnologySolutions from './TechnologySolutions';

const propTypes = {
  title: PropTypes.string.isRequired,
  subheader: PropTypes.string.isRequired,
  detailsTitle: PropTypes.string.isRequired,
  detailsDescription: PropTypes.shape({}).isRequired,
  detailsDescription2: PropTypes.shape({}),
  managementSolutions: PropTypes.array.isRequired,
  technologies: PropTypes.array.isRequired,
  companyQuote: PropTypes.shape({
    title: PropTypes.string,
    quote: PropTypes.string,
    icons: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
};

const defaultProps = {
  detailsDescription2: null,
};

const ServiceDetails = ({
  title,
  subheader,
  detailsTitle,
  detailsDescription,
  detailsDescription2,
  managementSolutions,
  technologies,
  companyQuote,
}) => (
  <Layout currentPage="/service-details" data-test="serviceDetailPageContainer">
    <PageHeader title={title} description={subheader} />
    <PageDetails
      title={detailsTitle}
      description={detailsDescription}
      description2={detailsDescription2}
      isDescInTwoColum
    />
    <ManagementSolutions
      key="adfaf"
      data={managementSolutions}
      data-test="serviceDetailpageManagementSolutionsContainer"
    />
    <TechnologySolutions
      technologies={technologies}
      data-test="serviceDetailpageTechnologySolutionsContainer"
    />
    <CompanyQuote companyQuote={companyQuote} />
  </Layout>
);

ServiceDetails.defaultProps = defaultProps;
ServiceDetails.propTypes = propTypes;
export default ServiceDetails;
