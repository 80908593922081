import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import classNames from 'classnames';

import renderContent from '../../../utils/rich-text-helpers';
import s from './ServiceDetails.module.scss';

const propTypes = {
  technologies: PropTypes.array,
};

const TechnologySolutions = ({ technologies }) => (
  <>
    {technologies?.length > 0 ? (
      <div className={[s.container, s.containerPaddingBtm].join(' ')}>
        {technologies.map((technology) => (
          <div
            className={s.techWrapper}
            key={technology.title}
            data-test="serviceDetailpageTechnologySolutions"
          >
            <div className={s.flaxOuter} data-aos="fade-up" data-aos-delay="400">
              <div className={classNames(s.flax30, s.mb_0)}>
                <Img fluid={technology.icon.fluid} className={s.logo} />
                <h2 className={s.serviceTitle}>{technology.title}</h2>
              </div>
              <div className={classNames(s.flax70, s.mb_0)}>
                {technology.description?.json?.content.map((content) => (
                  <div key={JSON.stringify(content)}>{renderContent(content, s.paragraph)}</div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    ) : (
      <></>
    )}
  </>
);

TechnologySolutions.defaultProps = {
  technologies: [],
};

TechnologySolutions.propTypes = propTypes;
export default TechnologySolutions;
