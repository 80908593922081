import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import classNames from 'classnames';

import renderContent from '../../../utils/rich-text-helpers';
import s from './ServiceDetails.module.scss';

const propTypes = {
  data: PropTypes.array,
};

const ManagementSolutions = ({ data }) => (
  <>
    {data?.length > 0 ? (
      <>
        {data.map((d, dIndex) => (
          <div
            key={d.title}
            className={s.container}
            data-test="serviceDetailpageManagementSolutions"
          >
            <div
              className={classNames(s.sectionWrapper, dIndex === data.length - 1 && s.brdBtm)}
              data-aos="fade-up"
              data-aos-delay="400"
            >
              <div className={s.flaxOuter}>
                <div className={s.flax70}>
                  <h4 className={s.titleText}>{d.title}</h4>
                  {d.description?.json?.content.map((content) =>
                    renderContent(content, s.paragraph),
                  )}
                </div>
                <div className={s.flax30}>
                  <Img fluid={d.icon.fluid} className={s.managementIcon} />
                </div>
              </div>
              <div className={s.flaxOuter}>
                {d.slides.data.map((slideData) => (
                  <div key={slideData.title} className={classNames(s.flax50, s.padd20)}>
                    <h3>{slideData.title}</h3>
                    <p>{slideData.description}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </>
    ) : (
      <></>
    )}
  </>
);

ManagementSolutions.defaultProps = {
  data: [],
};

ManagementSolutions.propTypes = propTypes;
export default ManagementSolutions;
